<template>
    <client-page>
        <page-section titAlign="text-center" class="page-section--first page-section--last">
            <template #tit>회원정보 입력</template>
            <join-input v-if="type === USER_TYPES.PERSON.value" code="join" skin="join-person" />
            <join-input v-if="type === USER_TYPES.COMPANY.value" code="join" skin="join-company" />
        </page-section>
    </client-page>
</template>

<script>
import { USER_TYPES } from "@/assets/variables";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import JoinInput from "@/components/client/form/join-input.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        JoinInput,
    },
    data: () => ({
        // btn_icon,
        USER_TYPES,
    }),
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        // 개인회원, 기업회원 구분자
        type() {
            return this.$route.query.type;
        },
        _certification() {
            return this.$route.query._certification;
        },
    },
    mounted() {
        if (!this.type || (this.type !== USER_TYPES.COMPANY.value && !this._certification)) {
            alert("잘못된 접근입니다.");
            this.$router.push("/");
        }
    },
    methods: {
        // init: function () {
        //     if (!this.accessToken) {
        //         this.$router.push("/login");
        //     }
        // },
    },
};
</script>

<style lang="scss" scoped>
.icon-next {
    display: inline-block;
    max-width: 20px;
    margin: 0 6px;
    transform: translateY(2px);
}
@media (min-width: 768px) {
    .icon-next {
        display: inline-block;
        max-width: 26px;
        margin: 0 10px;
        transform: initial;
    }
}
@media (min-width: 1024px) {
    .icon-next {
        margin: 0 20px;
    }
}
</style>
