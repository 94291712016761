<template>
    <client-page>
        <page-section titAlign="text-center" class="page-section--first page-section--last">
            <div class="d-flex justify-center mb-20px mb-lg-26px">
                <div class="w-60px h-60px w-lg-80px h-lg-80px rounded-pill secondary d-flex justify-center">
                    <v-icon class="white--text" x-large>done</v-icon>
                </div>
            </div>
            <div class="text-center">
                <div class="tit-wrap text-center">
                    <h3 class="tit">회원가입 완료</h3>
                </div>
                <p class="txt txt--dark">
                    {{ name }}({{ username }})님의 회원가입이<br />
                    성공적으로 완료되었습니다.
                </p>
            </div>
            <div class="btn-wrap btn-wrap--lg">
                <v-row class="row--xxs">
                    <!-- <v-col cols="12" lg="6">
                        <v-btn class="v-size--xx-large v-btn--width-fixed" v-bind="{ ...btn_primary }" href="/login">로그인</v-btn>
                    </v-col> -->
                    <v-col cols="12" lg="3"></v-col>
                    <v-col cols="12" lg="6">
                        <v-btn class="v-size--xx-large v-btn--width-fixed" v-bind="{ ...btn_secondary }" href="/">홈으로 가기</v-btn>
                    </v-col>
                    <v-col cols="12" lg="3"></v-col>
                </v-row>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import { btn_primary, btn_secondary, btn_icon } from "@/assets/variables";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        ClientPage,
        PageSection,
    },
    data: () => ({
        btn_icon,
        btn_primary,
        btn_secondary,
    }),
    computed:{
        name(){
            return this.$route.query.name;
        },
        username(){
            return this.$route.query.username;
        }
    }
};
</script>
