var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    staticClass: "icon-svg icon-check",
    attrs: {
      "viewBox": "0 0 60 60",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', [_c('path', {
    attrs: {
      "d": "M30 60C13.4573 60 0 46.5427 0 30C0 13.4573 13.4573 0 30 0C46.5427 0 60 13.4573 60 30C60 46.5427 46.5427 60 30 60ZM30 3.75C15.5251 3.75 3.75 15.5251 3.75 30C3.75 44.4749 15.5251 56.25 30 56.25C44.4749 56.25 56.25 44.4749 56.25 30C56.25 15.5251 44.4749 3.75 30 3.75Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M27.1879 39.9996C26.7077 39.9996 26.2279 39.8174 25.8626 39.4498L17.7378 31.325C17.0054 30.5921 17.0054 29.4046 17.7378 28.6722C18.4702 27.9398 19.6577 27.9398 20.3905 28.6722L27.1902 35.4723L42.1155 20.5474C42.848 19.8149 44.0354 19.8149 44.7678 20.5474C45.5002 21.2798 45.5002 22.4672 44.7678 23.1996L28.5177 39.4498C28.1478 39.8174 27.6681 39.9996 27.1879 39.9996Z"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }